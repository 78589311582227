import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const AlertDialog = ({children, isOpen, handleClose, onConfirm, title="",closeButtonText= "Fermer", confirmButtonText = "OK"}) => {

    const handleAlertClose = () => {
        handleClose && handleClose()
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAlertClose}>{closeButtonText}</Button>
                {onConfirm && (
                    <Button onClick={onConfirm} autoFocus>
                        {confirmButtonText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
