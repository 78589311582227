import React from "react";
import { Col, Row } from "react-bootstrap";
import BookingDate from "../components/BookingDates";
import BookingRecap from "../components/BookingRecap";
import Options from "./options";
import { useSearch } from "../SearchProvider";

export const ResumeOptions = () => {
    const {
        agencyList,
        selectedVehicle,
        bookingPreview,
        bookingPreviewLoading,
        couponData,
        setSelectedOptions,
        selectedOptions,
        selectedPackOption,
        setSelectedPackOption,
        options,
        packOptions,
        franchiseLevels,
        selectedFranchiseLevelIds,
        handleFranchiseLevelChange,
        kilometersStages,
        selectedKilometersStage,
        handleKilometersStageChange,
        formDataLoading,
        formData,
        selectedFranchiseLevels,
        kilometersStage,
        tariffFrequencyText,
        searchParameters,
        setStepHeader,
        setCouponData,
        showOptionImage,
        tariffPackages,
        selectedTariffPackage,
        setSelectedTariffPackage,
        handleTariffPackageChange,
    } = useSearch();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <p id={"option-page-title"} className={"fs20 py-3 bold"}>Choisissez vos options et garanties</p>
            <Row>
                <Col xl={4} className={"d-xl-block d-none"}>
                    <BookingDate
                        editable={false}
                        readOnlyData={searchParameters}
                        agencies={agencyList}
                        vehicle={selectedVehicle}
                    />
                    {selectedVehicle &&
                        <BookingRecap
                            vehicle={selectedVehicle}
                            bookingPreview={bookingPreview}
                            loading={bookingPreviewLoading}
                            couponData={couponData}
                            selectedFranchiseLevels={selectedFranchiseLevels}
                            kilometersStage={kilometersStage}
                            tariffFrequency={tariffFrequencyText}
                        />
                    }
                </Col>
                <Col xl={8} className={""}>
                    <Options
                        vehicle={selectedVehicle}
                        setStepHeader={setStepHeader}
                        setSelectedOptions={setSelectedOptions}
                        selectedOptions={selectedOptions}
                        selectedPackOption={selectedPackOption}
                        setSelectedPackOption={setSelectedPackOption}
                        options={options}
                        packOptions={packOptions}
                        bookingPreview={bookingPreview}
                        couponData={couponData}
                        setCouponData={setCouponData}
                        franchiseLevels={franchiseLevels}
                        selectedFranchiseLevelIds={selectedFranchiseLevelIds}
                        onFranchiseLevelChange={handleFranchiseLevelChange}
                        kilometersStages={kilometersStages}
                        selectedKilometersStage={selectedKilometersStage}
                        onKilometersStageChange={handleKilometersStageChange}
                        formDataLoading={formDataLoading}
                        tariffFrequency={tariffFrequencyText}
                        formData={formData}
                        showOptionImage={showOptionImage}
                        tariffPackages={tariffPackages}
                        selectedTariffPackage={selectedTariffPackage}
                        setSelectedTariffPackage={setSelectedTariffPackage}
                        onTariffPackageChange={handleTariffPackageChange}
                        // onTariffPackageChange="dddd"
                    />
                </Col>
            </Row>
        </>
    );
}