import React, {useContext, useEffect, useMemo, useState} from 'react';
import VehicleItem from "./VehicleItem";
import cx from "classnames"

import "./VehicleList.scss"
import classes from "./VehicleList.scss.json"
import {Col, Row} from "react-bootstrap";
import {Button, ButtonGroup} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThLarge} from "@fortawesome/free-solid-svg-icons";
import {faGripLines} from "@fortawesome/free-solid-svg-icons/faGripLines";
import { useStyleContext } from '../../../../customHooks/useStyleContext';

const VehicleList = ({vehicles, onVehicleSelect, searchParameters, loading, agencyCount, disableAgencies = false, startAgency}) => {

    const [availableVehicles, setAvailableVehicles] = useState([]);
    const [unavailableVehicles, setUnAvailableVehicles] = useState([]);

    const [selectedDisplayMode, setSelectedDisplayMode] = useState(null);

    const styleContext = useStyleContext();

    const searchResultFormat = useMemo(() => {
        if(selectedDisplayMode) { return selectedDisplayMode }
        let defaultFormat = styleContext?.booking_module_setting?.search_result_format ?? 'line'
        if(defaultFormat === 'choice') { return 'line' }
        return defaultFormat
    }, [styleContext, selectedDisplayMode]);

    useEffect(() => {
        setAvailableVehicles(vehicles.filter(vehicle => vehicle.is_available))
        setUnAvailableVehicles(vehicles.filter(vehicle => !vehicle.is_available))
    }, [vehicles]);


    const handleVehicleSelect = (vehicle) => {
        if(vehicle.is_available) {
            onVehicleSelect && onVehicleSelect(vehicle)
        }
    }

    const handleDisplayModeChange = (mode) => {
        setSelectedDisplayMode(mode)
    }

    return (
        <div className={cx(classes.vehicleList, "d-flex flex-column")}>
            {styleContext?.booking_module_setting?.search_result_format === 'choice' && (
                <div className={"d-flex justify-content-end mb-2"}>
                    <ButtonGroup variant="outlined" disableElevation>
                        <Button
                            variant={searchResultFormat === 'line' ? 'contained' : 'outlined'}
                            onClick={() => handleDisplayModeChange('line')}
                        ><FontAwesomeIcon icon={faGripLines} /></Button>
                        <Button
                            variant={searchResultFormat === 'grid' ? 'contained' : 'outlined'}
                            onClick={() => handleDisplayModeChange('grid')}
                        ><FontAwesomeIcon icon={faThLarge} /></Button>
                    </ButtonGroup>
                </div>
            )}
            {searchResultFormat === 'line' && (
                <>
                    {availableVehicles?.map((vehicle) => {
                        return <div key={`vehicle-item-${vehicle.id}`} >
                            <VehicleItem
                                agencyCount={agencyCount}
                                vehicle={vehicle}
                                searchParameters={searchParameters}
                                loading={loading}
                                onSelect={() => handleVehicleSelect(vehicle)}
                            />
                        </div>
                    })}
                    {styleContext?.app_config?.enable_custom_unavailability_text && styleContext?.app_config?.custom_unavailability_text?.length > 1 && (
                        <div className={"py-2 pb-4 bg-light bradius-5"}>
                            <p className={"text-center medium fst-italic fs14 "}>{styleContext?.app_config?.custom_unavailability_text}</p>
                        </div>
                    )}
                    {unavailableVehicles?.map((vehicle) => {
                        return <div key={`vehicle-item-${vehicle.id}`} >
                            <VehicleItem
                                agencyCount={agencyCount}
                                vehicle={vehicle}
                                loading={loading}
                                startAgency={startAgency}
                            />
                        </div>
                    })}
                </>
            )}
            {searchResultFormat === 'grid' && (
                <>
                    <Row>
                        {availableVehicles?.map((vehicle) => {
                            return <Col xs={12} md={6} key={`vehicle-item-${vehicle.id}`} className={"mb-3"} >
                                <VehicleItem
                                    agencyCount={agencyCount}
                                    vehicle={vehicle}
                                    searchParameters={searchParameters}
                                    loading={loading}
                                    onSelect={() => handleVehicleSelect(vehicle)}
                                    cardFormat={"grid"}
                                />
                            </Col>
                        })}
                    </Row>
                    {styleContext?.app_config?.enable_custom_unavailability_text && styleContext?.app_config?.custom_unavailability_text?.length > 1 && (
                        <div className={"py-2 pb-4 bg-light bradius-5"}>
                            <p className={"text-center medium fst-italic fs14 "}>{styleContext?.app_config?.custom_unavailability_text}</p>
                        </div>
                    )}
                    <Row>
                        {unavailableVehicles?.map((vehicle) => {
                            return <Col xs={12} md={6} key={`vehicle-item-${vehicle.id}`} className={"mb-3"}>
                                <VehicleItem
                                    agencyCount={agencyCount}
                                    vehicle={vehicle}
                                    loading={loading}
                                    startAgency={startAgency}
                                    cardFormat={"grid"}
                                />
                            </Col>
                        })}
                    </Row>
                </>
            )}
        </div>
    );
};

export default VehicleList;
