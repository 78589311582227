import React, {useContext, useMemo} from 'react';
import cx from "classnames";
import {Button, Col, Row} from "react-bootstrap";
import classes from "./VehicleList.scss.json";
import VehiclePicture from "../../../components/VehiclePicture/VehiclePicture";
import VehicleDefaultModel from "../../../components/VehicleDefaultModel/VehicleDefaultModel";
import VehicleSpecItem from "../../../components/VehicleSpecItem/VehicleSpecItem";
import AgencyName from "../../../components/AgencyName/AgencyName";
import VehiclePictureCarousel from "../../../components/VehiclePicture/VehiclePictureCarousel";
import { useStyleContext } from '../../../../customHooks/useStyleContext';
import AmountDisplay from '../../../components/AmountDisplay/AmountDisplay';

const VehicleGridItem = ({vehicle, loading = false, onSelect, agencyCount = 0, startAgency, subtitleMode, subtitle, displaySearchWarning, disableAgencies, pictures, descriptionLink}) => {
    const styleContext = useStyleContext();

    return (
        <div className={cx("w-100 mb-2 shadow-sm bg-white he-100 d-flex flex-column justify-content-between", {"opacity-50": !vehicle.is_available } )}>
            {pictures?.length > 1 ? (
                <VehiclePictureCarousel
                    pictures={pictures}
                    id={`vehicle_${vehicle?.id}`}
                    vehicleType={vehicle?.category?.vehicle_type}
                    height={250}
                />
            ): (
                <VehiclePicture
                    className={cx(classes.vehiclePicture, "w-100 bradius-5")}
                    url={pictures?.[0]?.url ?? vehicle?.picture_url}
                    vehicleType={vehicle?.category?.vehicle_type}
                    height={250}
                />
            )}
            <div className='p-3'>
                <div className={"d-flex flex-column fs14 "}>
                    <p className={"mt-3 mb-2"}>
                        <span className={"bold fs18 no-tr"}><VehicleDefaultModel vehicle={vehicle}/> </span>
                        {subtitleMode !== 'disabled' && <span className={"fst-italic fs14"}>{subtitle}</span>}
                    </p>
                    {descriptionLink && (
                        <p className={"fs14"}>
                            <a className='c-main' href={descriptionLink} target={"_blank"} rel={"noreferrer"}>Voir le descriptif</a>
                        </p>
                    )}
                    {vehicle?.category?.search_result_format === "custom" ? (
                        <Row className={"mt-2"}>
                            {vehicle?.category?.custom_vehicle_details?.map((detail, index) => {
                                return (detail?.icon || detail.label)&& <Col xs={12} key={`custom_detail_item_${index}_${detail?.id}`}><VehicleSpecItem websiteStyle={styleContext} iconName={detail?.icon ?? "ac"} label={detail.label ?? ""}/></Col>
                            })}
                        </Row>
                    ):(
                        <Row className={"mt-2"}>
                            {vehicle?.nb_places > 0 && (<Col xs={6}><VehicleSpecItem websiteStyle={styleContext} iconName={"seat"} label={`${vehicle.nb_places} places`}/></Col>)}
                            {vehicle?.specificities?.cooling && (<Col xs={6}><VehicleSpecItem websiteStyle={styleContext} iconName={"ac"}  label={"Climatisation"}/></Col>)}
                            {vehicle?.energy && (<Col xs={6}><VehicleSpecItem websiteStyle={styleContext} iconName={"gas"} label={vehicle.energy}/></Col>)}
                            {vehicle?.nb_doors ? (<Col xs={6}><VehicleSpecItem websiteStyle={styleContext} iconName={"door"} label={`${vehicle.nb_doors} portes`}/></Col>) : <></>}
                            {vehicle?.gearbox && (<Col xs={6}><VehicleSpecItem websiteStyle={styleContext} iconName={"shifter"} label={vehicle.gearbox}/></Col>)}
                        </Row>
                    )}
                </div>
                <div>
                    {loading ?
                        <div className={"d-flex align-items-center justify-content-center"} style={{minHeight: 50}}>
                            <p className={"text-center"} >Actualisation en cours...</p>
                        </div>
                        :
                        <>
                            {vehicle.is_available ?
                                (displaySearchWarning ?
                                        <div
                                            className={"d-flex align-items-center justify-content-center"}
                                            style={{minHeight: 50}}
                                        >
                                            <p className={"text-center medium fst-italic fs16 opacity-50"}>Veuillez renseigner
                                                vos dates {(agencyCount > 1 && !disableAgencies) && '& agences'}</p>
                                        </div>
                                        :
                                        <div className={"px-2 h-100 w-100 py-2 fs14"}>
                                        <Row className={"align-items-center  justify-content-center"}>
                                            {(styleContext?.payment_enabled) && (
                                                <Col xs={12}>
                                                    {styleContext?.payment_enabled && (<p className={"medium mb-1"}>Paiement en ligne</p>)}
                                                </Col>
                                            )}
                                            <Col xs={12}>
                                                {onSelect != null ? (
                                                    <Button
                                                        disabled={vehicle?.booking_amount < 0}
                                                        onClick={onSelect}
                                                        className={"c-white p-2 px-3 medium d-flex justify-content-center align-items-center flex-column w-100 fs14"}
                                                    >
                                                        <p>Réserver pour <span className={"medium no-tr"}>
                                                            <AmountDisplay
                                                                tariffDisplayMode={vehicle?.tariff_display_mode}
                                                                invoicingMode={vehicle?.pricing_group_invoicing_mode}
                                                                exclAmount={vehicle?.booking_excl_amount}
                                                                inclAmount={vehicle?.booking_incl_amount}
                                                                defaultAmount={vehicle?.booking_amount}
                                                            />
                                                            </span></p>
                                                    </Button>
                                                ) : (
                                                    <p className={"bradius-5 border-main c-main p-2 px-3 medium text-center fs16"}>
                                                        <AmountDisplay
                                                            tariffDisplayMode={vehicle?.tariff_display_mode}
                                                            invoicingMode={vehicle?.pricing_group_invoicing_mode}
                                                            exclAmount={vehicle?.booking_excl_amount}
                                                            inclAmount={vehicle?.booking_incl_amount}
                                                            defaultAmount={vehicle?.booking_amount}
                                                        />

                                                    </p>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                )
                                :
                                <>
                                    {(startAgency && !styleContext?.app_config?.disable_agency_choice && agencyCount > 1) && <p className={"text-center medium fst-italic fs14 opacity-50"}>Agence <AgencyName agency={startAgency} /></p>}
                                    <p className={"text-center medium fst-italic fs14 opacity-50"}>{styleContext?.app_config?.vehicle_search_result ? "Pas disponible à ces dates" : "Aucun véhicule disponible à ces dates"}</p>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
};
export default VehicleGridItem;
