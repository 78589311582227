import React from "react";
import { useSearch } from "../SearchProvider";
import { Col, Row } from "react-bootstrap";
import VehicleTypeTab from "../VehicleTypeTab/VehicleTypeTab";
import BookingDate from "../components/BookingDates";
import SearchModal from "../../components/SearchModal/SearchModal";
import SearchResult from "./searchResult";
import { useStyleContext } from "../../../customHooks/useStyleContext";
import { useCustomer } from "../../../customHooks/useCustomer";
import './search.scss';
import classes from './search.scss.json';

export const Search = () => {
    const styleContext = useStyleContext();
    const { isLog, setOpenModalLogin, isEnabled } = useCustomer();
    const {
        searchParameters,
        searchBookingParameters,
        searchResultData,
        vehicles,
        loading,
        agencyList,
        filterGroups,
        selectedFilterGroups,
        handleFilterSelect,
        handleSearchDateAndPlaceUpdate,
        handleVehicleSelect,
        setStepHeader,
        searchModalState,
        toggleSearchModal,
        minDays,
        minHours,
        minStartDate,
        maxStartDate,
        maxTimeBeforeStart,
        searchWarningMessage,
        objectFromQuery,
        selectedAgencies,
        materialTabIndex,
        changeTab,
        actualVehicleTypes
    } = useSearch();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {styleContext?.app_config?.enable_vehicle_type_tab &&
                <Row>
                    <Col xl={4} className={"d-xl-block d-none"}></Col>
                    <Col xl={8} className={""}>
                        <VehicleTypeTab
                            materialTabIndex={materialTabIndex}
                            hasVehicleTypeCar={false}
                            changeTab={changeTab}
                            actualVehicleTypes={actualVehicleTypes}
                            showGlobal
                        />
                    </Col>
                </Row>
            }

            <p className={"fs20 mt-3 bold"}>
                {loading ? 'Recherche...' : <>
                    {searchResultData?.total ? searchResultData?.total : 'Aucun'} Résultat{searchResultData?.total > 0 ? 's' : ''}
                </>}
            </p>

            {!isLog && isEnabled && (
                <div className={classes['customer-login']}>
                    <span>Vous avez déjà un compte ?</span>
                    <span onClick={() => setOpenModalLogin(true)}>Connectez-vous</span>
                </div>
            )}

            {searchWarningMessage && <div className={"bg-white"}><p className={"d-none d-xl-block fs12 medium mt-3 mb-3 fst-italic px-3 py-2"} style={{ color: "rgb(254,159,48)", backgroundColor: "rgb(255,226,166, 0.4)" }}>{searchWarningMessage}</p></div>}

            <Row className="mt-2">
                <Col xl={4} className={"d-xl-block d-none"}>
                    <BookingDate
                        params={objectFromQuery}
                        searchParameters={searchParameters}
                        agencies={agencyList}
                        onSearch={handleSearchDateAndPlaceUpdate}
                        loading={loading}
                        onInputClick={toggleSearchModal}
                    />
                </Col>
                <Col xl={8} className={""}>
                    <SearchModal
                        selectedInput={searchModalState.mode}
                        agencies={agencyList}
                        toggleOpen={searchModalState.open}
                        onSearch={handleSearchDateAndPlaceUpdate}
                        values={searchParameters}
                        onChange={handleSearchDateAndPlaceUpdate}
                        color={styleContext.colors?.['c-main']}
                        bookingParameters={searchBookingParameters}
                        singleAgency={styleContext?.app_config?.single_agency}
                        minDays={minDays}
                        minHours={minHours}
                        minStartDate={minStartDate}
                        maxStartDate={maxStartDate}
                        maxTimeBeforeStart={maxTimeBeforeStart}
                        disableAgencySelect={styleContext?.app_config?.disable_agency_choice}
                        warningMessage={styleContext?.configs?.["content_config-search_warning_message"] ?? null}
                    />
                    <SearchResult
                        agencyCount={agencyList?.length ?? 0}
                        vehicles={vehicles}
                        filterGroups={filterGroups}
                        selectedFilterGroups={selectedFilterGroups}
                        handleFilterSelect={handleFilterSelect}
                        setStepHeader={setStepHeader}
                        onVehicleSelect={handleVehicleSelect}
                        loading={loading}
                        searchParameters={searchParameters}
                        startAgency={selectedAgencies.start_agency}
                    />
                </Col>
            </Row>
        </>
    );
};