import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";

import "./VehicleList.scss"
import classes from "./VehicleList.scss.json"
import VehicleSpecItem from "../../../components/VehicleSpecItem/VehicleSpecItem";
import cx from "classnames";
import VehiclePicture from "../../../components/VehiclePicture/VehiclePicture";
import VehicleDefaultModel from "../../../components/VehicleDefaultModel/VehicleDefaultModel";
import AgencyName from "../../../components/AgencyName/AgencyName";
import VehicleGridItem from "./VehicleGridItem";
import VehiclePictureCarousel from "../../../components/VehiclePicture/VehiclePictureCarousel";
import { useStyleContext } from '../../../../customHooks/useStyleContext';
import AmountDisplay from '../../../components/AmountDisplay/AmountDisplay';


const VehicleItem = ({vehicle, selected = false, searchParameters, loading = false, onSelect, agencyCount = 0, startAgency, cardFormat = "line"}) => {

    const styleContext = useStyleContext();

    const [displaySearchWarning, setDisplaySearchWarning] = useState(false);

    const disableAgencies = useMemo(() => {
        return styleContext?.app_config?.disable_agency_choice ?? false
    }, [styleContext]);

    const subtitleMode = useMemo(() => {
        return vehicle?.category?.subtitle_mode ?? "default"
    }, [vehicle]);

    const subtitle = useMemo(() => {
        if(vehicle?.category?.subtitle_mode === "enabled") {
            return vehicle?.category?.custom_subtitle
        } else if(vehicle?.category?.subtitle_mode === "disabled") {
            return ""
        }
        return "ou similaire"
    }, [vehicle]);

    const descriptionLink = useMemo(() => {
        if(vehicle?.category?.view_description_link_mode === "enabled") {
            return vehicle?.category?.view_description_link
        } else if(vehicle?.category?.view_description_link_mode === "disabled") {
            return ""
        }
        return ""
    }, [vehicle]);

    const pictures = useMemo(() => {
        let allPictures = vehicle?.pictures ?? []
        if(vehicle?.category?.pictures?.length > 0) {
            allPictures = vehicle?.category?.pictures
        }
        return allPictures
    }, [vehicle]);

    useEffect(() => {
        if(searchParameters) {
            let showButton = true

            if(!searchParameters?.start_date || !searchParameters?.end_date ) {
                showButton = false
            }
            if((!searchParameters?.start_agency || !searchParameters?.end_agency)) {
                if (!styleContext?.app_config?.disable_agency_choice) {
                    showButton = false
                }
            }

            if(vehicle?.booking_amount == null || vehicle?.booking_amount < 0) {
                showButton = false
            }
            setDisplaySearchWarning(!showButton)
        }
    }, [searchParameters, vehicle]);

    if(cardFormat === 'grid') {
        return (
            <VehicleGridItem
                vehicle={vehicle}
                searchParameters={searchParameters}
                loading={loading}
                onSelect={onSelect}
                agencyCount={agencyCount}
                startAgency={startAgency}
                subtitleMode={subtitleMode}
                subtitle={subtitle}
                displaySearchWarning={displaySearchWarning}
                disableAgencies={disableAgencies}
                pictures={pictures}
                descriptionLink={descriptionLink}
            />
        )
    }


    return (
        <div className={cx({"mb-2" : !selected})}>
            {selected &&
                <Row>
                    <Col className={"px-0"}>
                        <div className={cx(classes.selectedLabel, "d-inline-block px-2")}>Votre sélection</div>
                    </Col>
                </Row>
            }
            <Row style={{minHeight: "109px"}} className={cx("shadow-sm p-0 py-2 px-md-3 align-items-center bg-white", {"opacity-50": !vehicle.is_available },{[classes.selected]: selected})}>
                <Col xs={4} lg={2} >
                    {pictures?.length > 1 ? (
                        <VehiclePictureCarousel
                            pictures={pictures}
                            id={`vehicle_${vehicle?.id}`}
                            vehicleType={vehicle?.category?.vehicle_type}
                        />
                    ): (
                        <VehiclePicture
                            className={cx(classes.vehiclePicture, "w-100 bradius-5")}
                            url={pictures?.[0]?.url ?? vehicle?.picture_url}
                            vehicleType={vehicle?.category?.vehicle_type}
                        />
                    )}
                </Col>
                <Col xs={8} lg={5} >
                    <div className={"d-flex flex-column px-md-4 fs14"}>
                        <p>
                            <span className={"bold fs18 no-tr"}><VehicleDefaultModel vehicle={vehicle}/> </span>
                            {subtitleMode !== 'disabled' && <span className={"fst-italic fs14"}>{subtitle}</span>}
                        </p>
                        {descriptionLink && (
                            <p className={"fs14"}>
                                <a className='c-main' href={descriptionLink} target={"_blank"} rel={"noreferrer"}>Voir le descriptif</a>
                            </p>
                        )}

                        {vehicle?.category?.search_result_format === "custom" ? (
                            <Row className={"mt-2"}>
                                {vehicle?.category?.custom_vehicle_details?.map((detail, index) => {
                                    return (detail?.icon || detail.label) && <Col xs={12} key={`custom_detail_item_${index}_${detail?.id}`}><VehicleSpecItem websiteStyle={styleContext} iconName={detail?.icon ?? "ac"} label={detail.label ?? ""}/></Col>
                                })}
                            </Row>
                        ):(
                            <Row className={"mt-2"}>
                                {vehicle?.nb_places > 0 && (<Col xs={6}><VehicleSpecItem websiteStyle={styleContext} iconName={"seat"} label={`${vehicle.nb_places} places`}/></Col>)}
                                {vehicle?.specificities?.cooling && (<Col xs={6}><VehicleSpecItem websiteStyle={styleContext} iconName={"ac"}  label={"Climatisation"}/></Col>)}
                                {vehicle?.energy && (<Col xs={6}><VehicleSpecItem websiteStyle={styleContext} iconName={"gas"} label={vehicle.energy}/></Col>)}
                                {vehicle?.nb_doors ? (<Col xs={6}><VehicleSpecItem websiteStyle={styleContext} iconName={"door"} label={`${vehicle.nb_doors} portes`}/></Col>) : <></>}
                                {vehicle?.gearbox && (<Col xs={6}><VehicleSpecItem websiteStyle={styleContext} iconName={"shifter"} label={vehicle.gearbox}/></Col>)}
                            </Row>
                        )}
                    </div>
                </Col>
                <Col lg={5} className={"h-100 mt-4 mt-lg-0"}>
                    {loading ?
                        <p className={"text-center"}>Actualisation en cours...</p>
                    :
                    <>
                        {vehicle.is_available ?
                            (displaySearchWarning ?
                                    <>
                                        <p className={"text-center medium fst-italic fs16 opacity-50"}>Veuillez renseigner vos dates {(agencyCount > 1 && !disableAgencies) && '& agences'}</p>
                                    </>
                            :
                                <div className={"px-2 h-100 w-100 py-2 fs14"}>
                                    <Row className={"align-items-center justify-content-lg-end justify-content-center"}>
                                        {(!selected && styleContext?.payment_enabled) && (
                                            <Col xs={6}>
                                                {styleContext?.payment_enabled && (<p className={"medium mb-1"}>Paiement en ligne</p>)}
                                            </Col>
                                        )}
                                        <Col xs={6}>
                                            {onSelect != null ? (
                                                <Button
                                                    disabled={vehicle?.booking_amount < 0}
                                                    onClick={onSelect}
                                                    className={"c-white p-2 px-3 medium d-flex justify-content-center align-items-center flex-column w-100 fs14"}
                                                >
                                                    <p>Réserver pour <span className={"medium no-tr"}>
                                                        {/* {vehicle?.booking_amount}{styleContext?.currency?.symbol ?? ''} */}
                                                        <AmountDisplay
                                                            tariffDisplayMode={vehicle?.tariff_display_mode}
                                                            invoicingMode={vehicle?.pricing_group_invoicing_mode}
                                                            exclAmount={vehicle?.booking_excl_amount}
                                                            inclAmount={vehicle?.booking_incl_amount}
                                                            defaultAmount={vehicle?.booking_amount}
                                                        />

                                                    </span></p>
                                                </Button>
                                            ) : (
                                                <p className={"bradius-5 border-main c-main p-2 px-3 medium text-center fs16"}>
                                                    {/* {vehicle?.booking_amount}{styleContext?.currency?.symbol ?? ''} */}
                                                    <AmountDisplay
                                                        tariffDisplayMode={vehicle?.tariff_display_mode}
                                                        invoicingMode={vehicle?.pricing_group_invoicing_mode}
                                                        exclAmount={vehicle?.booking_excl_amount}
                                                        inclAmount={vehicle?.booking_incl_amount}
                                                        defaultAmount={vehicle?.booking_amount}
                                                    />

                                                </p>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            )
                        :
                            <>
                                {(startAgency && !styleContext?.app_config?.disable_agency_choice && agencyCount > 1) && <p className={"text-center medium fst-italic fs14 opacity-50"}>Agence <AgencyName agency={startAgency} /></p>}
                                <p className={"text-center medium fst-italic fs14 opacity-50"}>{styleContext?.app_config?.vehicle_search_result ? "Pas disponible à ces dates" : "Aucun véhicule disponible à ces dates"}</p>
                            </>
                        }
                    </>}
                </Col>
            </Row>
        </div>
    );
};

export default VehicleItem;
