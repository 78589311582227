import 'react-hot-loader/patch'
import React from "react";
import ReactDOM from "react-dom";
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { AppContainer } from 'react-hot-loader'

import { BrowserRouter } from "react-router-dom";

import MainSearchComponent from "./pages/home";
import { LocalizationProvider } from "@mui/lab";
import frLocale from "date-fns/locale/fr"
import { initSentrySession } from "../../service/SentryService";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CustomerProvider } from '../../customHooks/useCustomer';
import { Routing } from './Routing';
import { StyleContext, styleContextRawData } from '../../customHooks/useStyleContext';

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

window.addEventListener('resize', appHeight)
appHeight();

const styleData = styleContextRawData();

initSentrySession(styleData?.env ?? 'dev');

const rootElement = document.getElementById("root");

let primaryColor = styleData?.colors?.["c-main"]

if (primaryColor === undefined || primaryColor === null || primaryColor === "") {
    primaryColor = "#7165e3"
}

const theme = createTheme({
    palette: {
        primary: {
            main: primaryColor,
        }
    },
});

const renderApp = (Component) => {
    if (!rootElement) {
        return;
    }
    
    ReactDOM.render(
        <AppContainer>
            <ThemeProvider theme={theme}>
                <StyleContext.Provider value={styleData}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                        <CustomerProvider>
                            <Routing />
                        </CustomerProvider>
                    </LocalizationProvider>
                </StyleContext.Provider>
            </ThemeProvider>
        </AppContainer>,
        rootElement
    );
};

renderApp();