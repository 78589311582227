import React, {useContext, useEffect} from 'react';
import FilterGroupList from "../components/FilterGroup/FilterGroupList";
import VehicleList from "../components/VehicleList/VehicleList";
import LoadingContainer from "../../components/LoadinContainer/LoadingContainer";
import { useStyleContext } from '../../../customHooks/useStyleContext';

const SearchResult = ({filterGroups,selectedFilterGroups,vehicles, handleFilterSelect,setStepHeader, onVehicleSelect, loading, searchParameters,agencyCount,startAgency }) => {
    const styleContext = useStyleContext();

    useEffect(() => {
        setStepHeader(0)
    }, [])
    return (
        <div>
            <div>
                {filterGroups?.length > 0 && (
                    <div className={"mb-3 d-none d-lg-block"}>
                        <FilterGroupList filterGroups={filterGroups} selectedFilterGroup={selectedFilterGroups} onSelect={handleFilterSelect}/>
                    </div>
                )}

                <LoadingContainer loading={loading && !vehicles?.length } title={"recherche en cours..."} color={styleContext?.colors?.["c-main"] ?? 'white'}>

                    {vehicles.length > 0 ?
                        <VehicleList
                            vehicles={vehicles}
                            onVehicleSelect={onVehicleSelect}
                            searchParameters={searchParameters}
                            loading={loading}
                            agencyCount={agencyCount}
                            disableAgencies={styleContext?.app_config?.disable_agency_choice ?? false}
                            startAgency={startAgency}
                        />
                    :
                        <div className={"d-flex justify-content-center align-items-center"}>
                            <p className={"mt-5 fs18 medium"}>Aucun véhicule disponible</p>
                        </div>
                    }
                </LoadingContainer>
            </div>
        </div>
    );
};

export default SearchResult;
