import React, {useContext, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import Header from "../../components/Header/Header";
import {Button, Col, Container, Modal, ModalBody, Row} from "react-bootstrap"
import BookingDate from "../components/BookingDates";
import apiService from "../../../service/api";
import {Redirect, Route, Routes, useNavigate, useLocation, useParams, Navigate} from "react-router-dom";
import SearchResult from "./searchResult";
import Options from "./options";
import CustomerInformations from "./customerInformations";
import useParseParamsFromQuery from "../../../customHooks/useParseParamsFromQuery";
import BookingRecap from "../components/BookingRecap";
import {faChevronDown, faChevronUp, faFilter, faTag,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FilterGroupList from "../components/FilterGroup/FilterGroupList";

import cx from "classnames";
import ModalHeader from "react-bootstrap/ModalHeader";

import SearchStepper from "../components/SearchStepper/SearchStepper";
import {
    addHours,
    addMonths,
    differenceInDays,
    differenceInHours,
    format,
    getDay,
    isAfter,
    isBefore,
    isValid,
    parseISO,
    startOfDay
} from 'date-fns';
import SearchModal from "../../components/SearchModal/SearchModal";
import ResizeDetector from "react-resize-detector";
import {Collapse} from "@mui/material";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import moment from "moment";
import frLocale from "date-fns/locale/fr"
import sandbox from "../../../service/Sandbox";
import VehicleTypeTab from '../VehicleTypeTab/VehicleTypeTab';
import ResponsiveSearchForm from "../components/ResponsiveSearchForm";
import BookingCouponAmount from "../../components/BookingCouponAmount/BookingCouponAmount";
import { useCustomer } from '../../../customHooks/useCustomer';
import { useStyleContext } from '../../../customHooks/useStyleContext';
import AmountDisplay from '../../components/AmountDisplay/AmountDisplay';


const MainSearchComponent = ({}) => {
    let vehicleTypes = ""

    const navigate = useNavigate()
    // const routeLocation = useLocation()
    const routeLocation = window.location;

    const customerContext = useCustomer();

    const objectFromQuery = useParseParamsFromQuery()
    const objectFromQueryStr = JSON.stringify(objectFromQuery)
    const styleContext = useStyleContext();

    const [filterGroups, setFilterGroups] = useState([])
    const [selectedFilterGroups, setSelectedFilterGroups] = useState([])
    const [selectedFilterVehicleTypes, setSelectedFilterVehicleTypes] = useState([])
    const [agencyList, setAgencyList] = useState([])
    const [stepHeader, setStepHeader] = useState(0)
    const [loadingTasks, setLoadingTasks] = useState([])
    const [filterModalOpen, setFilterModalOpen] = useState(false)
    const [searchModalState, setSearchModalState] = useState({
        mode: null,
        open: false
    })
    const [bookingPreviewLoading, setBookingPreviewLoading] = useState([])
    const [bookingPreview, setBookingPreview] =  useState()
    const [bookingPreviewCollapse, setBookingPreviewCollapse] =  useState(false)


    const [selectedVehicle, setSelectedVehicle] = useState(null)
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedPackOption, setSelectedPackOption] = useState(null)
    const [selectedFranchiseLevelIds, setSelectedFranchiseLevelIds] = useState([])
    const [selectedKilometersStage, setSelectedKilometersStage] = useState(null)

    const [searchParameters, setSearchParameters] = useState({})
    const [vehicles, setVehicles] = useState([])

    const [customer, setCustomer] = useState(customerContext.isLog ? customerContext.customer : {});

    const [searchResultData, setSearchResultData] = useState({
        nb_page: 0,
        total: 0,
        page: 0
    })
    const [isFirst, setIsFirst] = useState(true)

    const [cdwSetting, setCdwSetting] = useState(null)
    const [options, setOptions] = useState([])
    const [packOptions, setPackOptions] = useState([])
    const [franchiseLevels, setFranchiseLevels] = useState([])
    const [kilometersStages, setKilometersStages] = useState([])
    // const [youngDriverSurcharges ,setYoungDriverSurcharges] = useState([])
    const [customerBirthDate, setCustomerBirthDate] = useState(null)

    const [selectedAgencies, setSelectedAgencies] = useState({
        start_agency: null,
        end_agency: null
    })

    const [isLoading, setIsLoading] = useState(false)

    const [couponData, setCouponData] = useState({})
    const [searchBookingParameters, setSearchBookingParameters] = useState({})

    const [minDays, setMinDays] = useState(0)
    const [minHours, setMinHours] = useState(0)
    const [minTimeBeforeStart, setMinTimeBeforeStart] = useState(3)
    const [maxTimeBeforeStart, setMaxTimeBeforeStart] = useState(12)

    const [alertDialogOpen, setAlertDialogOpen] = useState(false)
    const [customFieldValues, setCustomFieldValues] = useState({})
    const [searchDateErrors, setSearchDateErrors] = useState([])

    const [materialTabIndex, setMaterialTabIndex] = useState(0)
    const [actualVehicleTypes ,setActualVehicleTypes] = useState([])

    const [formDataLoading, setFormDataLoading] = useState(false)

    const [tariffFrequency, setTariffFrequency] = useState("day")
    const [tariffDisplayMode, setTariffDisplayMode] = useState("")
    const [tariffPackages, setTariffPackages] = useState([])

    const [formData, setFormData] = useState(null)

    const defaultLocation = useMemo(() => {
        const newLocation = routeLocation
        newLocation.pathname = "/"
        return newLocation
    }, [])

    const tariffFrequencyText = useMemo(() => {
        if(tariffFrequency === "month") return "mois"
        if(tariffFrequency === "hour") return "heure"
        return "jour"
    }, [tariffFrequency])
    const minStartDate = useMemo(() => addHours(new Date(), minTimeBeforeStart), [minTimeBeforeStart]);
    const maxStartDate = useMemo(() => addMonths(new Date(), maxTimeBeforeStart), [maxTimeBeforeStart]);

    const selectedFranchiseLevels = useMemo(() => {
        return franchiseLevels.filter(f => selectedFranchiseLevelIds.includes(f.id))
    }, [selectedFranchiseLevelIds, franchiseLevels])

    const agencyExtraAmount = useMemo(() => {
        return (bookingPreview?.agency_extra_amount?.start ?? 0) + (bookingPreview?.agency_extra_amount?.end ?? 0)
    }, [bookingPreview]);


    const kilometersStage = useMemo(() => {
        return kilometersStages.find(f => f.id === selectedKilometersStage)
    }, [kilometersStages, selectedKilometersStage])

    const searchWarningMessage = useMemo(() => {
        return styleContext?.configs?.["content_config-search_warning_message"] ?? null
    }, [styleContext])

    const backgroundColor = useMemo(() => {
        return styleContext?.configs?.["content_config-bm_background_color"] ?? null
    }, [styleContext])

    const loading = useMemo(() => {
        return loadingTasks?.length > 0
    }, [loadingTasks]);

    useEffect(() => {
        if (customerContext.isLog) {
            setCustomer(customerContext.customer);
        }

        getFilterGroups()
        getOptionData()
        // console.log("location", navigatelocation.pathname !== "/")
        if(!selectedVehicle && navigatelocation.pathname !== "/") {
            navigate("/")
        }

        setStepHeader(0);

        return () => {
            setFilterGroups(undefined);
        };
    }, [customerContext.isLog]);

    useEffect(() => {
        let searchData = {...searchParameters}
        if(isFirst) {
            const params = objectFromQuery;
            // const tomorrow = addDays(startOfHour(new Date()), 1)
            // const in2days = addDays(startOfHour(new Date()), 2)

            searchData = {
                start_date : params.start_date ? params.start_date : '' ,
                end_date : params.end_date ? params.end_date :  '',
                start_agency: params.start_agency ? params.start_agency : '',
                end_agency: params.end_agency ? params.end_agency : ''
            }
            if(window.agency) {
                searchData.start_agency = window.agency.id
                searchData.end_agency = window.agency.id
            }
            setSearchParameters(searchData)
            setIsFirst(false)
        }
        searchVehicle(searchData)
    }, [selectedFilterGroups, selectedFilterVehicleTypes])

    useEffect(() => {
        if (filterGroups?.length > 0 && objectFromQueryStr !== "{}") {
            const filterGroupId = Number(objectFromQuery?.filter_group_id)
            handleFilterSelect(filterGroupId)
        }
    }, [filterGroups, objectFromQueryStr])

    useEffect(() => {
        if(selectedVehicle) {
            getOptionData(selectedVehicle?.category?.id)
        }
    }, [selectedVehicle])

    useEffect(() => {
        if(agencyList.length > 0) {
            setSelectedAgencies(prevState => {
                let newState = {...prevState}
                if(agencyList?.length === 1) {
                    newState.start_agency = agencyList?.[0]
                    newState.end_agency = agencyList?.[0]
                    setSearchParameters({
                        start_agency: newState.start_agency.id,
                        end_agency: newState.end_agency.id,
                        start_date: searchParameters.start_date,
                        end_date: searchParameters.end_date
                    })
                    searchVehicle()
                } else {
                    if(searchParameters?.start_agency) {
                        newState.start_agency = getAgencyWithId(searchParameters?.start_agency)
                    }
                    if(searchParameters?.end_agency) {
                        newState.end_agency = getAgencyWithId(searchParameters?.end_agency)
                    }
                }
                return newState
            })

            if(searchParameters?.start_agency) {
                const agency = getAgencyWithId(searchParameters?.start_agency)
                if(agency?.min_days) {
                    updateBookingParametersWithAgency(agency)
                } else {
                    updateBookingParametersWithAgency()
                }
            }
        }

    }, [agencyList, searchParameters.start_agency, searchParameters.end_agency])

    useEffect(() => {
        if (agencyList?.length > 0 && !objectFromQuery?.start_agency && !objectFromQuery?.end_agency ){
            setSelectedAgencies(prevState => {
                let newState = {...prevState}
                // newState.start_agency = getAgencyWithId(agencyList?.[0]?.id)
                // newState.end_agency = getAgencyWithId(agencyList?.[0]?.id)
                return newState
            })
            setSearchParameters(prevState => {
                let newState = {...prevState}
                // newState.start_agency = agencyList?.[0]?.id
                // newState.end_agency = agencyList?.[0]?.id
                if (searchParameters?.start_date && searchParameters?.end_date){
                    const searchData = {
                        start_date: searchParameters?.start_date,
                        end_date: searchParameters?.end_date,
                        start_agency: newState.start_agency,
                        end_agency: newState.end_agency,
                    }
                    searchVehicle(searchData)
                }
                return newState
            })
            // console.log(searchParameters)
        }
    }, [agencyList])

    useEffect(() => {
        if (searchParameters.start_agency && searchParameters?.start_date) {
            // console.log("@@@@@", searchParameters);
            const startAgency = Number(searchParameters.start_agency)
            const startDate = isValid(searchParameters?.start_date) ? searchParameters?.start_date : parseISO(searchParameters?.start_date)
            const agency = agencyList.find(a => a.id == startAgency)
            const day = getDay(startDate);
            // console.log("@@@@@@", agency);
            const availabilityPeriods = agency?.availability_periods
            if (availabilityPeriods?.length > 0){
                availabilityPeriods?.forEach(period => {
                    if (period?.day === day && period.status === 'available') {
                        
                    }
                })
            }
        }
    }, [searchParameters.start_agency, searchParameters.end_agency]);

    useEffect(() => {
        const defaultAgency = agencyList?.find(agency => agency?.is_default === true)

        if(defaultAgency && !searchParameters.start_agency) {
            updateBookingParametersWithAgency(defaultAgency)
        } else {
            updateBookingParametersWithAgency()
        }

    }, [searchBookingParameters])

    useEffect(() => {
        updateAmount()
    }, [selectedVehicle, selectedOptions, selectedPackOption,couponData, selectedFranchiseLevelIds, selectedKilometersStage, customerBirthDate])

    useEffect(() => {
        let defaultFranchiseLevel = null
        let hasType = false
        let hasDefault = false
        const defaultFranchiseLevelIds = franchiseLevels?.filter(fl => {
            if(!hasType) { hasType = fl.franchise_level_type != null; }
            if(!hasDefault) { hasDefault = fl.include_in_booking === true; }
            return fl.include_in_booking === true
        })?.map(fl => fl.id) ?? []

        if((defaultFranchiseLevelIds?.length > 0 && !hasType ) || !hasDefault) {
            franchiseLevels?.forEach(fl => {
                if(!defaultFranchiseLevel || fl.price < defaultFranchiseLevel.price){
                    defaultFranchiseLevel = fl
                }
            })
            if (defaultFranchiseLevel) {
                defaultFranchiseLevelIds.push(defaultFranchiseLevel.id)
            }
        }
        setSelectedFranchiseLevelIds(defaultFranchiseLevelIds)
        // setSelectedFranchiseLevel(defaultFranchiseLevel?.id ?? null)
        // onFranchiseLevelChange && onFranchiseLevelChange(defaultFranchiseLevel?.id ?? null)
    }, [franchiseLevels]);


    useEffect(() => {
        let defaultKilometersStage = null
        kilometersStages?.forEach(kmStage => {
            if(!defaultKilometersStage || kmStage.price_per_day < defaultKilometersStage.price_per_day){
                defaultKilometersStage = kmStage
            }
        })
        setSelectedKilometersStage(defaultKilometersStage?.id ?? null)
        // onKilometersStageChange && onKilometersStageChange(defaultKilometersStage?.id ?? null)
    }, [kilometersStages]);


    useEffect(() => {
        if(bookingPreview?.option_pack_amount?.franchise_level) {
            // setSelectedFranchiseLevel(bookingPreview?.option_pack_amount?.franchise_level?.id)
            setSelectedFranchiseLevelIds([bookingPreview?.option_pack_amount?.franchise_level?.id])
        }
        if(bookingPreview?.option_pack_amount?.kilometers_stage) {
            setSelectedKilometersStage(bookingPreview?.option_pack_amount?.kilometers_stage?.id)
        }
    }, [bookingPreview])


    useEffect(() => {
        if(window.vehicle_type) {
            if(routeLocation.pathname === `/${window.vehicle_type}/recherche/`) {
                console.log({defaultLocation})
                navigate("/")
                return
            }
        }
        if(window.agency) {
            const formattedPathName = routeLocation.pathname.replace(/\/+$/, '').toLowerCase()
            const agencyCode = window.agency_code.toLowerCase()
            if(formattedPathName === `/agence/${agencyCode}/recherche`|| formattedPathName === `/agence/${agencyCode}`) {
                console.log({defaultLocation})
                navigate("/")
                return
            }
        }
        if(routeLocation.pathname === "/") {
            setSelectedVehicle(null)
        } else if (routeLocation.pathname === "/options" && customerContext.isLog == false) {
            setCustomer({});
        } else {
            setSearchModalState(prevState => ({...prevState, open: false}))
        }
        // trigger trackers
        let trackers = styleContext?.app_config?.trackers
        if(trackers?.length > 0) {
            const snapTracker = trackers?.find((item) => item?.provider === 'snapchat')
            const fcbkTracker = trackers?.find((item) => item?.provider === 'facebook')
            const gtagTracker = trackers?.find((item) => item?.provider === 'googletagmanager')
            if(snapTracker || fcbkTracker || gtagTracker) {
                if (routeLocation.pathname === '/') {
                    //search result = '/'
                    snapTracker?.events?.filter(evt => evt?.action_trigger === 'search_result')?.forEach((event) => {
                        snaptr('track', event.event_name)
                    })
                    fcbkTracker?.events?.filter(evt => evt?.action_trigger === 'search_result')?.forEach((event) => {
                        fbq('track', event.event_name)
                    })
                    gtagTracker?.events?.filter(evt => evt?.action_trigger === 'search_result')?.forEach((event) => {
                        dataLayer.push({event: event.event_name})
                    })
                } else if (routeLocation.pathname === '/options') {
                    // options = '/options'
                    snapTracker?.events?.filter(evt => evt?.action_trigger === 'options_page')?.forEach((event) => {
                        snaptr('track', event.event_name)
                    })
                    fcbkTracker?.events?.filter(evt => evt?.action_trigger === 'options_page')?.forEach((event) => {
                        fbq('track', event.event_name)
                    })
                    gtagTracker?.events?.filter(evt => evt?.action_trigger === 'options_page')?.forEach((event) => {
                        dataLayer.push({event: event.event_name})
                    })
                } else if (routeLocation.pathname === '/infos-client') {
                    // search_user_form = '/infos-client'
                    snapTracker?.events?.filter(evt => evt?.action_trigger === 'search_user_form')?.forEach((event) => {
                        snaptr('track', event.event_name)
                    })
                    fcbkTracker?.events?.filter(evt => evt?.action_trigger === 'search_user_form')?.forEach((event) => {
                        fbq('track', event.event_name)
                    })
                    gtagTracker?.events?.filter(evt => evt?.action_trigger === 'search_user_form')?.forEach((event) => {
                        dataLayer.push({event: event.event_name})
                    })
                }
            }
        }
    }, [routeLocation.pathname])

    useLayoutEffect(() => {
        getVehicleTypes().then(res => {
            const list = res?.map(item => ({name: item?.name ?? "", color: item?.color ?? "#000", default_icon_name: item?.default_icon_name ?? ""}))
            setActualVehicleTypes(list)
            if (window.vehicle_type){
                const index = list.findIndex(e => e?.name?.toLowerCase() == window?.vehicle_type.toLowerCase())
                if (index >= 0){
                    setMaterialTabIndex(index + 1)
                }
            }
        })
    }, [])

    const getVehicleTypes = async () => {
        let list = []
        return apiService.post('/api/company/vehicle-types', {}, false)
            .then(res => {
                return res
            })
            .catch(() => {})
            .finally(() => {
                return list
            })
    }
    const getOptionData = (categoryId = null) => {

        const dataOptionRequest = {
            categoryId: categoryId,
            agencyId: searchParameters.start_agency,
            start_date: formatDate(searchParameters.start_date, "yyyy-MM-dd HH:mm",""),
            end_date: formatDate(searchParameters.end_date, "yyyy-MM-dd HH:mm",""),
        }

        if(styleContext?.app_config?.vehicle_search_result) {
            dataOptionRequest["vehicleId"] = selectedVehicle?.id ?? ''
        }

        setSelectedPackOption(null)
        setSelectedOptions([])
        setSelectedKilometersStage(null)

        setFormDataLoading(true)
        apiService?.get(`/api/company/booking-request-data`, dataOptionRequest, false).then(res => {
            console.log("booking request data", res)

            if(res?.options) { setOptions(res.options?.filter(option => option?.code_option != "cdw")) }
            if(res?.pack_options) { setPackOptions(res.pack_options) }
            if(res?.franchise_levels) { setFranchiseLevels(res.franchise_levels) }
            if(res?.pack_options) { setPackOptions(res.pack_options) }
            if(res?.kilometers_stages) { setKilometersStages(res.kilometers_stages) }
            if(res?.tariff_frequency) { setTariffFrequency(res.tariff_frequency) }
            if (res?.tariff_display_mode) {setTariffDisplayMode(res.tariff_display_mode)}
            if (res?.tariff_packages) {setTariffPackages(res.tariff_packages)}
            // if (res?.young_driver_surcharges) { setYoungDriverSurcharges(res.young_driver_surcharges) }
            if(!res.error) { setFormData(res) }
        }).finally(() => {
            setFormDataLoading(false)
        })
    }
    const getFilterGroups = () => {
        apiService.get("/api/search/filter-groups", [], false)
        .then((result) => {
            setFilterGroups(result)
            // console.log("DONE")
        })

        const agencyListParams = {}
        if(window.agency_code) { agencyListParams.agency_code = window.agency_code }

        apiService.get("/api/search/agency-list", agencyListParams, false)
            .then((result) => {
                // console.log("Agency List")
                if(result.agencies) {
                    setAgencyList(result.agencies)
                }
                if(result.booking_parameters) {
                    setSearchBookingParameters(result.booking_parameters)
                }
            })

    }
    const searchVehicle = (searchParams = searchParameters) => {
        let filterString = ""
        selectedFilterGroups.forEach((item,index) => {
            filterString += index === 0 ? item : `,${item}`
        })
        const data = {
            ...searchParams,
            start_date: formatDate(searchParams.start_date, "yyyy-MM-dd HH:mm",""),
            end_date: formatDate(searchParams.end_date, "yyyy-MM-dd HH:mm",""),
            filters : filterString,
            vehicle_types:  vehicleTypes.length > 0  ? vehicleTypes : (window?.vehicle_type ?? ""),
        }
        if((data?.start_date === '' || data?.end_date === '') && vehicles?.length > 0) {
            return false
        }
        if(window.agency) {
            data.page_agency = window.agency.id
        }
        // console.log(data)
        const loadingId = Math.random()
        setLoadingTasks(prevState => [...prevState, loadingId])
        apiService.get("/api/search", data, true)
            .then((result) => {
                setSearchResultData({
                    nb_page: result?.numberPage ?? 0,
                    total: result?.total ?? 0,
                    page: result?.paginationNumber ?? 0
                })
                setVehicles(prevState => {
                    const requestList = result?.list ?? []
                    if(result.paginationNumber == 0) {
                        return requestList
                    }
                    return [...prevState, ...requestList]
                })
            }).finally(() =>  setLoadingTasks(prevState => prevState.filter((item) => item !== loadingId)))
    }

    const updateBookingParametersWithAgency = (agency) => {
        let bookingMinDays = searchBookingParameters?.min_days ?? 0
        let bookingMinHours = searchBookingParameters?.min_hours ?? 0
        let bookingMinTimeBeforeStart = searchBookingParameters?.min_time_before_start ?? 3
        let bookingMaxTimeBeforeStart = searchBookingParameters?.max_time_before_start ?? 12

        // console.log(agency)
        agency?.min_days && (bookingMinDays = agency?.min_days)
        agency?.min_time_before_start && (bookingMinTimeBeforeStart = agency?.min_time_before_start)
        agency?.max_time_before_start && (bookingMaxTimeBeforeStart = agency?.max_time_before_start)

        // console.log({bookingMinDays,bookingMinTimeBeforeStart,bookingMaxTimeBeforeStart})
        setMinDays(bookingMinDays)
        setMinHours(bookingMinHours)
        setMinTimeBeforeStart(bookingMinTimeBeforeStart)
        setMaxTimeBeforeStart(bookingMaxTimeBeforeStart)
    }

    const handleFilterSelect = (filterGroupId) => {
        setSelectedFilterGroups(prevState => {
            let newState = []
            if(filterGroupId) {
                if(prevState.includes(filterGroupId)) {
                    newState = prevState.filter((item) => item !== filterGroupId)
                } else {
                    newState = [...prevState, filterGroupId]
                }
            }
            return newState
        })
    }

    const handleSearchDateAndPlaceUpdate = (data) => {
        if(agencyList?.length === 1) {
            data.start_agency = agencyList?.[0].id
            data.end_agency = agencyList?.[0].id
        }
        const formatted = formatSearchedDates(data)
        setSearchParameters(formatted)

        if( !checkMinDays(formatted) ) {
            searchVehicle(formatted)
        }
    }

    const handleCustomerUpdate = (data, type='customer') => {
        if(type === 'customer') {
            setCustomer(data)
        } else if(type === 'custom_field') {
            setCustomFieldValues(data)
        }
    }

    const handleVehicleSelect = (vehicle) => {

        const formatted = formatSearchedDates(searchParameters)
        if (checkMinDays(formatted)) {
            toggleAlertDialog()
            return
        }


        setSelectedVehicle(vehicle)
        setOptions([])
        // console.log(searchParameters)
        const newLocation = routeLocation
        newLocation.pathname = "/options"
        navigate(newLocation)
    }
    const toggleAlertDialog = () => {
        setAlertDialogOpen(prevState => !prevState)
    }

    const handleBookingSave = ({withDrivingLicence}) => {
        const formatted = formatSearchedDates(searchParameters)
        if (checkMinDays(formatted)) {
            toggleAlertDialog()
            return
        }

        // console.log("build booking parameters")
        let data = {
            "enable_amount_calculation" : 1,
            "start_date": formatDate(searchParameters.start_date, "yyyy-MM-dd HH:mm","") ,
            "end_date": formatDate(searchParameters.end_date, "yyyy-MM-dd HH:mm",""),
            "source": "fleetee_web",
            "status": 20,
            "start_agency": searchParameters.start_agency ?? '',
            "end_agency": searchParameters.end_agency ?? '',
            "customer": {
                "gender" : customer.gender,
                "email": customer.email,
                "phone_number": customer.phone_number,
                "first_name": customer.first_name,
                "last_name": customer.last_name,
                "address": {
                    "street_line_one": customer.address,
                    "postcode": customer.postcode,
                    "city": customer.city
                },
                "birthday": customer.birthday,
                "country": customer.country,
                "birth_city": customer.birth_city,
            },
            "options": [...selectedOptions],
            "flight_number": customer.flight_number,
            "customer_comment" : customer.comment,
            "pack_option" : {
                "id" : selectedPackOption
            },
            "drivers": customer.drivers ?? [],
            "custom_fields" : customFieldValues,
            "vehicle_category" : selectedVehicle?.category?.id ?? ''
        }

        if(styleContext?.app_config?.vehicle_search_result) {
            data["vehicle_id"] = selectedVehicle?.id ?? ''
        }
        if(selectedFranchiseLevelIds?.length > 0) {
            data.franchise_levels = selectedFranchiseLevelIds.map((id) => ({id}))
        }
        if(selectedKilometersStage) {
            data.kilometers_stage = {id: selectedKilometersStage}
        }

        if(couponData?.id) {
            data.coupons = [{...couponData}]
        }
        if (withDrivingLicence) {
            data.customer.driving_license = {
                "first_name": customer.first_name,
                "last_name": customer.last_name,
                "birthday" : customer.birthday,
                "license_date" : customer.license_date,
                "license_number" : customer.license_number,
                "license_country" : customer.license_country
            }
        }
        // console.log(data)
        createBooking(data)
    }

    const createBooking = (data) => {
        const loadingId = Math.random()
        setLoadingTasks(prevState => [...prevState, loadingId])
        apiService.post("/api/booking", data, false)
            .then((result) => {
                // console.log(result)
                if((result.error || result.status === 500) && !result.id) {
                    if(result.code && sandbox.BOOKING_CREATE_ERROR[result.code]) {
                        setSearchDateErrors([sandbox.BOOKING_CREATE_ERROR[result.code]])
                    } else {
                        setSearchDateErrors(["Une erreur s'est produite."])
                    }
                    toggleAlertDialog()
                } else {
                    if(styleContext?.mode === "booking_process") {
                        window.location.href = `/reservation?process_id_booking=${result.id}`
                    } else {
                        window.location.href = `/reservation`
                    }
                }
            }).finally(() =>  setLoadingTasks(prevState => prevState.filter((item) => item !== loadingId)))
    }
    const toggleFilterModal = () => {
        // console.log("toggle modal")
        setFilterModalOpen(prevState => !prevState)
    }

    const updateAmount = () => {
        if(selectedVehicle?.category?.id && searchParameters?.start_date && searchParameters?.start_date) {
            const data = {
                category_id: selectedVehicle?.category.id,
                start_date: formatDate(searchParameters?.start_date, 'yyyy-MM-dd HH:mm', ''),
                end_date: formatDate(searchParameters?.end_date, 'yyyy-MM-dd HH:mm', ''),
                options: [...selectedOptions],
                start_agency : searchParameters.start_agency,
                end_agency : searchParameters.end_agency,
                option_pack_id: selectedPackOption,
            }
            if(couponData?.id) {
                data.coupons = [{...couponData}]
            }

            if(selectedFranchiseLevelIds?.length > 0) {
                const franchiseLevelIds = [...new Set(selectedFranchiseLevelIds)]
                data.franchise_levels = franchiseLevelIds.map((id) => ({id}))
            }
            if(selectedKilometersStage) {
                data.kilometers_stage = {id: selectedKilometersStage}
            }
            if(styleContext?.app_config?.vehicle_search_result) {
                data.vehicle_id = selectedVehicle?.id ?? ''
            }
            if (customerBirthDate && isValid(customerBirthDate)){
                data.customer_birth_date = format(customerBirthDate, 'yyyy-MM-dd')
            }

            const requestTime = Date.now()
            setBookingPreviewLoading(prevState => {
                return [...prevState, requestTime]
            })
            apiService.post("/api/booking/calculate-amount", data).then((res) => {
                // console.log(res)
                setBookingPreview(res)
                setBookingPreviewLoading(prevState => {
                    return prevState.filter(item => item !== requestTime)
                })
            }).catch(() => {
                setBookingPreviewLoading(prevState => {
                    return prevState.filter(item => item !== requestTime)
                })
            })
            // console.log(data)
        }
    }

    const toggleSearchModal = (mode) => {
        // console.log("toggle search modal")

        setSearchModalState(prevState => {
            return {...prevState,
                open: !prevState.open,
                mode: mode
            }
        })
        if(navigatelocation.pathname !== "/") {navigate("/")}
    }

    const formatDate = (dateStr, dateFormat = "yyyy-MM-dd", defaultValue = "...") => {
        let res = defaultValue
        if(isValid(dateStr)) {
            res = format(dateStr, dateFormat)
        } else if(dateStr !== "") {
            const date = parseISO(dateStr)
            if(isValid(date)) {
                res = format(date, dateFormat)
            }
        }
        return res
    }

    const getAgencyWithId = (agencyId) => {
        return agencyList?.find(item => parseInt(item?.id) === parseInt(agencyId))
    }

    const checkMinDays = (data) => {
        // console.log(data)

        let startDate = data.start_date
        if(moment.isMoment(data.start_date)) {
            startDate = data.start_date.toDate()
        } else if (!isValid(data.start_date)) {
            startDate =  parseISO(data.start_date)
        }

        let endDate = data.end_date
        if(moment.isMoment(data.end_date)) {
            endDate = data.end_date.toDate()
        } else if (!isValid(data.end_date)) {
            endDate =  parseISO(data.end_date)
        }

        const dateDiff = differenceInDays(endDate, startDate)
        const hourDiff = differenceInHours(endDate, startDate)

        // console.log(startDate)
        // console.log(endDate)
        // console.log(dateDiff)

        let dateErrors = []

        if( minDays > 0 && Math.ceil(hourDiff/24) < minDays) {
            dateErrors.push(`La durée minimale de location est de ${minDays} jours.`)
        }
        //MIN TIME BEFORE START
        if ( isBefore(startOfDay(startDate), startOfDay(minStartDate))  ) {
            dateErrors.push(`Veuillez sélectionner une date de réservation à partir du ${format(minStartDate,'dd MMMM yyyy', {locale: frLocale})} pour cette agence.`)
        }

        //MAX TIME BEFORE START
        if ( isAfter(startOfDay(startDate), startOfDay(maxStartDate))  ) {
            dateErrors.push(`Les réservations sont possibles jusqu'à ${maxTimeBeforeStart} mois à l'avance pour cette agence.`)
        }

        setSearchDateErrors(dateErrors)
        return dateErrors.length > 0
    }

    const formatSearchedDates = (data) => {
        if(data?.start_date) {
            if(moment.isMoment(data?.start_date)) {
                data.start_date = data?.start_date.format("YYYY-MM-DD HH:mm")
            }
        }
        if(data?.end_date) {
            if(moment.isMoment(data?.end_date)) {
                data.end_date = data?.end_date.format("YYYY-MM-DD HH:mm")
            }
        }
        return data
    }

    const changeTab = (vehicle_type, tabIndex) => {
        setMaterialTabIndex(tabIndex)
        vehicleTypes = [vehicle_type]
        searchVehicle()
    }

    const handleFranchiseLevelChange = (value) => {
        // setSelectedFranchiseLevel(value)
        setSelectedFranchiseLevelIds(prevState => {
            if(value === null) { return [] }
            if(prevState.includes(value)) {
                return prevState.filter(item => item !== value)
            } else {
                const franchiseLevelItem = franchiseLevels.find(item => item.id === value)

                const newState = prevState.filter(prevId => {
                    const prevFranchiseLevel = franchiseLevels.find(item => item.id === prevId)
                    return prevFranchiseLevel?.franchise_level_type?.name !== franchiseLevelItem?.franchise_level_type?.name
                })
                return [...newState, value]
            }
        })
    }
    const handleKilometersStageChange = (value) => {
        setSelectedKilometersStage(value)
    }

    const handleBirthDateChange = (birthDate) => {
        // if(birthDate){
            setCustomerBirthDate(birthDate)
        // } else {
        //     setCustomerBirthDate(null)
        // }
    }

    const bookingAmount = useMemo(() => {
        if (!bookingPreview || !styleContext) return null;

        const { currency } = styleContext;
        const currencySymbol = currency?.symbol ?? '';
        const tariffDisplayMode = bookingPreview?.tariff_display_mode;

        if (!tariffDisplayMode) {
            return <>{bookingPreview.amount}{currencySymbol}</>;
        }
        switch (tariffDisplayMode) {
            case 'pricing_group':
                return bookingPreview?.pricing_group_invoicing_mode === 'ht'
                    ? <>{bookingPreview?.excl_amount}{currencySymbol} HT</>
                    : <>{bookingPreview?.incl_amount}{currencySymbol}</>;
            case 'ht':
                return <>{bookingPreview?.excl_amount}{currencySymbol} HT</>;
            default:
                return <>{bookingPreview?.incl_amount}{currencySymbol}</>;
        }
    }, [bookingPreview, styleContext]);

    return (
        <>
            <Header
                styles={styleContext}
                stepHeader={stepHeader}
                headerAlignment={"justify-content-between"}
                rightComponent={
                    <>
                        <div className={cx("w-100 d-lg-flex d-none", {"justify-content-end": !styleContext?.header?.configs?.["config-hide_logo_on_app"], "justify-content-center": styleContext?.header?.configs?.["config-hide_logo_on_app"] })}>
                            <ResizeDetector
                                handleWidth
                                render={({ width }) => {
                                    return (
                                        <div className={"w-75"}>
                                            <SearchStepper step={stepHeader} hideLabel={false} canHideLabel={false}/>
                                        </div>
                                )}}
                            />
                        </div>
                        <div>
                            {filterGroups?.length > 0 && (<>
                                <div className={"p-4 position-relative d-block d-lg-none"} onClick={toggleFilterModal}>
                                    <FontAwesomeIcon icon={faFilter} className={"c-grey fs30"}/>
                                    {selectedFilterGroups?.length > 0 && (<span className={"bold c-white bg-main justify-content-center align-items-center d-flex h20 w20 rounded-circle"}
                                        style={{position: 'absolute', top: '10px', right: '10px'}}
                                    >{selectedFilterGroups.length}</span>)}
                                </div>
                                <Modal
                                    show={filterModalOpen}
                                    fullscreen={true}
                                    contentClassName={"w-100"}
                                    centered
                                    animation={true}
                                >
                                    <ModalHeader className={cx('d-flex justify-content-between')}>
                                        <p className={"medium fs18"}>Filtres {selectedFilterGroups?.length > 0 && (<span className={'fst-italic bold c-main'}> - {selectedFilterGroups?.length}</span>)}</p>
                                        <Button onClick={toggleFilterModal}>Fermer</Button>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FilterGroupList filterGroups={filterGroups} selectedFilterGroup={selectedFilterGroups} onSelect={handleFilterSelect}/>
                                    </ModalBody>
                                </Modal>
                            </>)}
                        </div>
                    </>
                }
            />
            <div className={cx("booking-module", {"bg-light-grey": !Boolean(backgroundColor)})} style={{
                paddingTop: '75px',
                height: "calc(var(--app-height))",
                overflowY: "scroll",
                overflowX: "hidden",
                backgroundColor: backgroundColor ?? "transparent"
            }}>
                {/*<div className={"d-flex d-lg-none flex-column mt-2 bg-white"}>*/}
                {/*    <SearchStepper key={`search-modal-stepper`} step={stepHeader} hideLabel={true} alternativeLabel canHideLabel={false} />*/}
                {/*</div>*/}
                <div className={cx("shadow-sm bg-white d-xl-none", {"sticky-top" : routeLocation.pathname !== "/" })}>
                    <div className={"d-flex justify-content-around"}>
                        <div className={"p-2 w-100"}>
                            <div className={"d-flex justify-content-between"}>
                                <p className={"medium"}>{routeLocation.pathname === "/" ? 'Votre recherche' : 'Récapitulatif de votre réservation'}</p>
                                {/*<p className={"c-main fst-italic underline medium pe-2 pointer"} onClick={() => toggleSearchModal('recap')}>Modifier</p>*/}
                            </div>
                            <ResponsiveSearchForm
                                editable={routeLocation.pathname === "/"}
                                agencies={agencyList}
                                params={objectFromQuery}
                                searchParameters={searchParameters}
                                onSearch={handleSearchDateAndPlaceUpdate}
                                loading={loading}
                                onInputClick={toggleSearchModal}
                            />

                            {/*{!styleContext?.app_config?.disable_agency_choice && (*/}
                            {/*    <p className={"fs14"}>*/}
                            {/*        <FontAwesomeIcon icon={faHome} className={"me-2"} />*/}
                            {/*        <span>*/}
                            {/*            <AgencyName className={"medium"} agency={selectedAgencies?.start_agency} />*/}
                            {/*            <FontAwesomeIcon icon={faArrowCircleRight} className={"c-main mx-2"} />*/}
                            {/*            <AgencyName className={"medium"} agency={selectedAgencies?.end_agency} />*/}
                            {/*        </span>*/}
                            {/*    </p>*/}
                            {/*)}*/}
                            {/*<p className={"fs14"}>*/}
                            {/*    <FontAwesomeIcon icon={faCalendarAlt} className={"me-2"} />*/}
                            {/*    <span>Du <span className={"medium"}>{formatDate(searchParameters.start_date, "dd/MM/yyyy HH'h'mm")}</span> au <span className={"medium"}>{formatDate(searchParameters.end_date, "dd/MM/yyyy HH'h'mm")}</span></span>*/}
                            {/*</p>*/}

                            {selectedVehicle && (
                                <>
                                    <hr className={"my-2"}/>
                                    <div className={"d-flex justify-content-between pointer"} onClick={() => setBookingPreviewCollapse(prevState => !prevState)}>
                                        {/* <p>Montant Total :  <span className={"bold no-tr"}>{bookingPreviewLoading?.length ? "..." : `${parseFloat(bookingPreview?.total_amount).toFixed(2)} ${styleContext?.currency?.symbol ?? ''}`}</span></p> */}
                                        <p>Montant Total :  <span className={"bold no-tr"}>{bookingPreviewLoading?.length ? "..." :
                                            <AmountDisplay
                                                tariffDisplayMode={bookingPreview?.tariff_display_mode}
                                                invoicingMode={bookingPreview?.pricing_group_invoicing_mode}
                                                exclAmount={bookingPreview?.total_excl_amount}
                                                inclAmount={bookingPreview?.total_incl_amount}
                                                defaultAmount={bookingPreview?.total_amount}
                                            />
                                            }</span></p>
                                        <p className={"fst-italic"}><span className={"ms-4"}><FontAwesomeIcon icon={bookingPreviewCollapse ? faChevronUp : faChevronDown} /></span></p>
                                    </div>
                                    <Collapse in={bookingPreviewCollapse} >
                                        <div className={"d-flex justify-content-between mt-3"}>
                                            <p className={"regular"}>Durée</p>
                                            <p className={"medium"}>{bookingPreview?.nb_days} jour{bookingPreview?.nb_days > 1 && 's'}</p>
                                        </div>
                                        {(selectedFranchiseLevels?.length > 0 || bookingPreview?.option_pack_amount?.franchise_level) && (
                                            <>
                                                {bookingPreview?.option_pack_amount?.franchise_level ? (
                                                    <>
                                                        <div className={"d-flex justify-content-between mt-1"}>
                                                            <p className={"regular"}>Montant de la franchise</p>
                                                            {loading?.length ? <>...</> : (
                                                                <p className={"bold"}>{`${bookingPreview?.option_pack_amount?.franchise_level?.franchise_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                                            )}
                                                        </div>
                                                        <div className={"d-flex justify-content-between mt-1"}>
                                                            <p className={"regular"}>Montant de la caution</p>
                                                            {loading?.length ? <>...</> : (
                                                                <p className={"bold"}>{`${bookingPreview?.option_pack_amount?.franchise_level?.deposit_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                                            )}
                                                        </div>
                                                    </>
                                                ): (
                                                    <>

                                                        {selectedFranchiseLevels?.map(franchiseLevel => {
                                                            return <div
                                                                key={`franchise-level-recap-mobile-${franchiseLevel?.id}`}>
                                                                <div className={"d-flex justify-content-between mt-1"}>
                                                                    <p className={"regular"}>
                                                                        {franchiseLevel?.franchise_level_type?.name ?
                                                                            <span>Franchise {franchiseLevel?.franchise_level_type?.name}</span>
                                                                            :
                                                                            <span>Montant de Franchise</span>
                                                                        }
                                                                    </p>
                                                                    {loading?.length ? <>...</> : (
                                                                        <p className={"bold"}>{`${franchiseLevel?.franchise_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        })}
                                                        {bookingPreview?.deposit && (
                                                            <div className={"d-flex justify-content-between mt-1"}>
                                                                <p className={"regular"}>Montant de la caution</p>
                                                                {loading?.length ? <>...</> : (
                                                                    <p className={"bold"}>{`${bookingPreview?.deposit?.amount} ${styleContext?.currency?.symbol ?? ''}`}</p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {(kilometersStage || bookingPreview?.tariff_package?.kilometers || bookingPreview?.option_pack_amount?.kilometers_stage) &&
                                            <div className={"d-flex justify-content-between mt-1"}>
                                                <p className={"regular"}>Kilométrage</p>
                                                {loading?.length ? <>...</> : (
                                                    bookingPreview?.tariff_package ? (
                                                        <p className={"bold"}>{bookingPreview?.tariff_package?.unlimited_kilometers ? "Illimité" : `${bookingPreview?.tariff_package?.kilometers}Km`} </p>
                                                    ):(
                                                        bookingPreview?.option_pack_amount?.kilometers_stage ? (
                                                            <p className={"bold"}>{bookingPreview?.option_pack_amount?.kilometers_stage?.unlimited_kilometers ? "Illimité" : `${bookingPreview?.option_pack_amount?.kilometers_stage?.kilometers_per_day}Km/${tariffFrequencyText}`} </p>
                                                        ) : (
                                                            <p className={"bold"}>{kilometersStage?.unlimited_kilometers ? "Illimité" : `${kilometersStage?.kilometers_per_day}Km/${tariffFrequencyText}`} </p>
                                                        )
                                                    )
                                                )}
                                            </div>
                                        }

                                        <div className={"d-flex justify-content-between mt-3"}>
                                            <p className={"medium"}>Montant <span className={"fs12 fst-italic"}>(hors option)</span></p>
                                            <p className={"bold"}>
                                                {/* {bookingPreview?.amount ? parseFloat(bookingPreview.amount) : ''} {styleContext?.currency?.symbol ?? ''} */}
                                                {bookingAmount}
                                            </p>
                                        </div>
                                        {/* {agencyExtraAmount > 0 ? <>
                                            <div className={"d-flex justify-content-between mt-3"}>
                                                <p className={"medium"}>Frais d'agence</p>
                                                {loading.length ? <>...</> : <p className={"bold"}>{`${agencyExtraAmount} ${styleContext?.currency?.symbol ?? ''}`}</p>}
                                            </div>
                                        </> : <></>} */}
                                        {bookingPreview?.option_amount_details?.cdw_option ? <>
                                            <div className={"d-flex justify-content-between mt-2"}>
                                                <p className={"medium"}>{bookingPreview?.option_amount_details?.cdw_option?.option_name}</p>
                                                {bookingPreviewLoading.length ? <>...</> : <p className={"bold"}>{`${bookingPreview?.option_amount_details?.cdw_option?.amount}${styleContext?.currency?.symbol ?? ''}`}</p>}
                                            </div>
                                        </> : <></>}

                                        {selectedFranchiseLevels?.length > 0 && bookingPreview?.franchise_level_amount != null ? <>
                                            {bookingPreview?.franchise_level_amount > 0 && (
                                                <div className={"d-flex justify-content-between mt-1"}>
                                                    <p className={"regular"}>Rachat de franchise</p>
                                                    {loading?.length ? <>...</> : <p className={"bold"}>{`${bookingPreview?.franchise_level_amount} ${styleContext?.currency?.symbol ?? ''}`}</p>}
                                                </div>
                                            )}
                                        </> : <></>}

                                        {bookingPreview?.option_pack_amount?.name ? <>
                                            <div className={"d-flex justify-content-between mt-2"}>
                                                <p className={"regular"}>{bookingPreview?.option_pack_amount?.name}</p>
                                                {bookingPreviewLoading?.length ? <>...</> : <p className={"bold"}>{`${bookingPreview?.option_pack_amount?.amount}${styleContext?.currency?.symbol ?? ''}`}</p>}
                                            </div>
                                        </> : <></>}
                                        {(bookingPreview?.option_pack_amount?.options?.length > 0 || bookingPreview?.option_amount_details?.options?.length) > 0 && (
                                            <>
                                                <p className={"mt-2 medium"}>Options</p>
                                                <hr className={"my-2"}/>
                                                <div className={""}>
                                                    {bookingPreview?.option_pack_amount?.options?.map((option) => {
                                                        return <div key={`option-pack-recap-${option.option_id}`} className={"d-flex justify-content-between"}>
                                                            <p className={"regular"}>{option.option_name}</p>
                                                            {bookingPreviewLoading.length ? <>...</> : <p className={"light fst-italic c-main"}>Inclus</p>}
                                                        </div>
                                                    })}
                                                    {bookingPreview?.option_amount_details?.options?.length ?
                                                        <>
                                                            {bookingPreview?.option_amount_details?.options?.map((option) => {
                                                                return <div key={`option-recap-${option.option_id}`} className={"d-flex justify-content-between"}>
                                                                    <p className={"regular"}>{option.option_name} {option.quantity > 1 && `(x${option.quantity})`}</p>
                                                                    {bookingPreviewLoading.length ? <>...</> : <p className={"bold"}>{`${option.amount}${styleContext?.currency?.symbol ?? ''}`}</p>}
                                                                </div>
                                                            })}
                                                        </> :
                                                        <></>
                                                    }
                                                    {(!bookingPreview?.option_pack_amount?.options.length && !bookingPreview?.option_amount_details?.options?.length) && <>
                                                        <p className={"fst-italic c-grey"}>Aucune option</p>
                                                    </>  }

                                                </div>
                                            </>
                                        )}
                                        {couponData?.code && <>
                                            <div className={"d-flex justify-content-between mt-2 border bradius-5 p-2"}>
                                                <div className={"d-flex align-items-center"}>
                                                    <FontAwesomeIcon className={"me-2"} icon={faTag}/>
                                                    <p><span
                                                        className={"medium"}>{couponData?.code ?? couponData?.name} {couponData.type === "percentage" && <>(-{parseInt(couponData?.value)} %)</>}</span>
                                                    </p>
                                                </div>
                                                {bookingPreviewLoading?.length ? <>...</> :
                                                    <BookingCouponAmount
                                                        className={"regular fst-italic"}
                                                        couponAmount={bookingPreview?.coupon_amount}
                                                        couponType={couponData?.type}
                                                        invoicingMode={bookingPreview?.invoicing_mode}
                                                        vatRate={bookingPreview?.vat_rate}
                                                        currencySymbol={styleContext?.currency?.symbol}
                                                    />
                                                }
                                            </div>
                                        </>}
                                        {styleContext?.app_config?.booking_conditions?.length > 0 && (
                                            <>
                                                <hr/>
                                                <div className={"bg-white p-2 mt-3"}>
                                                <p className={"medium fs14 underline mb-1"}>Conditions de
                                                        réservation :</p>
                                                    <div className={"bg-white p-2"}>
                                                        {styleContext?.app_config?.booking_conditions?.map( (conditionItem, index) => {
                                                            return <p key={`booking-condition-text-${index}`} className={"fs14"}>- {conditionItem}</p>
                                                        }) }
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Collapse>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={cx( {"bg-light-grey": !Boolean(backgroundColor)})} >
                    <Container className={""}>
                        <Routes>
                            <Route path={"/"} exact={true}>
                                {styleContext?.app_config?.enable_vehicle_type_tab && 
                                    <Row>
                                        <Col xl={4} className={"d-xl-block d-none"}></Col>
                                        <Col xl={8} className={""}>
                                            <VehicleTypeTab
                                                materialTabIndex={materialTabIndex}
                                                hasVehicleTypeCar={false}
                                                changeTab={changeTab}
                                                actualVehicleTypes={actualVehicleTypes}
                                                showGlobal
                                            />
                                        </Col>
                                    </Row>
                                }

                                <p className={"fs20 mt-3 bold mb-2"}>
                                    {loading ? 'Recherche...' : <>
                                        {searchResultData?.total ? searchResultData?.total : 'Aucun'} Résultat{searchResultData?.total > 0 ? 's' :''}
                                    </>}
                                </p>

                                {searchWarningMessage && <div className={"bg-white"}><p className={"d-none d-xl-block fs12 medium mt-3 mb-3 fst-italic px-3 py-2"} style={{color: "rgb(254,159,48)", backgroundColor: "rgb(255,226,166, 0.4)"}}>{searchWarningMessage}</p></div>}

                                <Row>
                                    <Col xl={4} className={"d-xl-block d-none"}>
                                        <BookingDate
                                            params={objectFromQuery}
                                            searchParameters={searchParameters}
                                            agencies={agencyList}
                                            onSearch={handleSearchDateAndPlaceUpdate}
                                            loading={loading}
                                            onInputClick={toggleSearchModal}
                                        />
                                    </Col>
                                    <Col xl={8} className={""}>
                                        <SearchModal
                                            selectedInput={searchModalState.mode}
                                            agencies={agencyList}
                                            toggleOpen={searchModalState.open}
                                            onSearch={handleSearchDateAndPlaceUpdate}
                                            values={searchParameters}
                                            onChange={handleSearchDateAndPlaceUpdate}
                                            color={styleContext.colors?.['c-main']}
                                            bookingParameters={searchBookingParameters}
                                            singleAgency={styleContext?.app_config?.single_agency}
                                            minDays={minDays}
                                            minHours={minHours}
                                            minStartDate={minStartDate}
                                            maxStartDate={maxStartDate}
                                            maxTimeBeforeStart={maxTimeBeforeStart}
                                            disableAgencySelect={styleContext?.app_config?.disable_agency_choice}
                                            warningMessage={styleContext?.configs?.["content_config-search_warning_message"] ?? null}
                                        />
                                        <SearchResult
                                            agencyCount={agencyList?.length ?? 0}
                                            vehicles={vehicles}
                                            filterGroups={filterGroups}
                                            selectedFilterGroups={selectedFilterGroups}
                                            handleFilterSelect={handleFilterSelect}
                                            setStepHeader={setStepHeader}
                                            onVehicleSelect={handleVehicleSelect}
                                            loading={loading}
                                            searchParameters={searchParameters}
                                            startAgency={selectedAgencies.start_agency}
                                        />
                                    </Col>
                                </Row>
                            </Route>
                            <Route path={"/options"} exact={true}>
                                <p id={"option-page-title"} className={"fs20 py-3 bold"}>Choisissez vos options et garanties</p>
                                <Row>
                                    <Col xl={4} className={"d-xl-block d-none"}>
                                        <BookingDate
                                            editable={false}
                                            readOnlyData={searchParameters}
                                            agencies={agencyList}
                                            vehicle={selectedVehicle}
                                        />
                                        {selectedVehicle &&
                                            <BookingRecap
                                                vehicle={selectedVehicle}
                                                bookingPreview={bookingPreview}
                                                loading={bookingPreviewLoading}
                                                couponData={couponData}
                                                selectedFranchiseLevels={selectedFranchiseLevels}
                                                kilometersStage={kilometersStage}
                                                tariffFrequency={tariffFrequencyText}
                                            />
                                        }
                                    </Col>
                                    <Col xl={8} className={""}>
                                        <Options
                                            vehicle={selectedVehicle}
                                            setStepHeader={setStepHeader}
                                            setSelectedOptions={setSelectedOptions}
                                            selectedOptions={selectedOptions}
                                            selectedPackOption={selectedPackOption}
                                            setSelectedPackOption={setSelectedPackOption}
                                            options={options}
                                            packOptions={packOptions}
                                            bookingPreview={bookingPreview}
                                            couponData={couponData}
                                            setCouponData={setCouponData}
                                            franchiseLevels={franchiseLevels}
                                            selectedFranchiseLevelIds={selectedFranchiseLevelIds}
                                            onFranchiseLevelChange={handleFranchiseLevelChange}
                                            kilometersStages={kilometersStages}
                                            selectedKilometersStage={selectedKilometersStage}
                                            onKilometersStageChange={handleKilometersStageChange}
                                            formDataLoading={formDataLoading}
                                            tariffFrequency={tariffFrequencyText}
                                            tariffDisplayMode={tariffDisplayMode}
                                            tariffPackages={tariffPackages}
                                        />
                                    </Col>
                                </Row>
                            </Route>
                            <Route path={"/infos-client"} exact>
                                <p className={"fs20 py-3 bold"}>Inscrivez vous pour confirmer votre réservation</p>
                                <Row className={"pb-3"}>
                                    <Col xl={4} className={"d-xl-block d-none"}>
                                        <BookingDate
                                            editable={false}
                                            readOnlyData={searchParameters}
                                            agencies={agencyList}
                                            // vehicle={vehicles[0]}
                                        />
                                        {selectedVehicle &&
                                            <BookingRecap
                                                vehicle={selectedVehicle}
                                                bookingPreview={bookingPreview}
                                                loading={bookingPreviewLoading}
                                                couponData={couponData}
                                                selectedFranchiseLevels={selectedFranchiseLevels}
                                                kilometersStage={kilometersStage}
                                                tariffFrequency={tariffFrequencyText}
                                            />
                                        }
                                    </Col>
                                    <Col xl={8} className={""}>
                                        <CustomerInformations
                                            setStepHeader={setStepHeader}
                                            customerData={customer}
                                            onChange={handleCustomerUpdate}
                                            onSaveBooking={handleBookingSave}
                                            customerLoading={loading}
                                            bookingPreview={bookingPreview}
                                            customFieldValues={customFieldValues}
                                            onBirthDateChange={handleBirthDateChange}
                                        />
                                    </Col>
                                </Row>
                            </Route>
                            {selectedVehicle ? <></>: <Navigate to={defaultLocation} />}
                        </Routes>
                    </Container>
                </div>
            </div>

            <AlertDialog
                isOpen={alertDialogOpen}
                handleClose={toggleAlertDialog}
            >
                <div>
                    {searchDateErrors?.map((item, index) => {
                        return <p key={`parameter-error-item-${index}`} className={"medium fs18"}>{item}</p>
                    })}
                </div>
            </AlertDialog>

        </>
    );
};

export default MainSearchComponent;
