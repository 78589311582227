import { useNavigate, useParams } from "react-router-dom";


// FIXME: This hook is not used in the project
const useParseParamsFromQuery = (func, deps) => {

    const navigate = useNavigate();
    const params = useParams();
    const parseParams = (querystring) => {

        // parse query string
        const params = new URLSearchParams(querystring);

        const obj = {};

        // iterate over all keys
        for (const key of params.keys()) {
            if (params.getAll(key).length > 1) {
                obj[key] = params.getAll(key);
            } else {
                obj[key] = params.get(key);
            }
        }

        return obj;
    };

    //It returns an object of key-value pairs, from the ones in the query
    return history?.location?.search ? parseParams(history?.location?.search) : {};

}

export default useParseParamsFromQuery;